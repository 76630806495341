<template>
  <header class="bg-white py-4 shadow-md z-10 relative">
    <div class="container mx-auto px-4">
      <div class="flex items-center justify-between">
        <!-- Logo on the far left with increased size -->
        <router-link to="/" class="flex items-center">
          <img :src="require('@/assets/logo.jpg')" alt="Logo" class="h-16 mr-4" />
        </router-link>

        <!-- Navigation links at the center with separation -->
        <div class="md:flex items-center space-x-4 flex-grow">
          <button @click="toggleMobileNav" class="md:hidden text-blue-500"></button>

          <div
            class="md:flex space-x-4"
            :class="{ 'hidden md:flex': !showMobileNav }"
          >
            <router-link
              to="/"
              class="menu-link"
            >
              Home
            </router-link>
            <div class="separator"></div>
            <router-link
              to="/about"
              class="menu-link"
            >
              About
            </router-link>
            <div class="separator"></div>
            <router-link
              to="/reportedcase"
              class="menu-link"
            >
              Report Case
            </router-link>
            <div class="separator"></div>
            <router-link
              to="/watersourcestatus"
              class="menu-link"
              style="font-size: 14px;"
            >
              Water Source Status
            </router-link>
            <div class="separator"></div>
            <router-link
              to="/feeds"
              class="menu-link"
              style="font-size: 14px;"
            >
              Feeds
            </router-link>
            <div class="separator"></div>
            <router-link
              to="/contact"
              class="menu-link"
              style="font-size: 14px;"
            >
              Contact
            </router-link>
          </div>
        </div>

        <!-- Sign Up at the far right corner -->
        <nav class="items-center space-x-4 hidden md:flex">
          <button
            @click="showSignUpForm"
            class="bg-blue-500 text-white py-2 px-3 rounded-lg hover:bg-blue-600 text-sm transition duration-300 ease-in-out"
          >
            Sign Up
          </button>
        </nav>
      </div>
    </div>

    <!-- Breadcrumbs for mobile and tablet views -->
    <div class="md:hidden px-4 mt-2">
      <router-link
        to="/"
        class="text-sm text-black hover:text-blue-500 transition duration-300 ease-in-out"
      >
        Home
      </router-link>
      <router-link
        to="/about"
        class="text-sm text-black hover:text-blue-500 transition duration-300 ease-in-out"
        style="font-size: 14px;"
      >
        About
      </router-link>
      <router-link
        to="/reportedcase"
        class="text-sm text-black hover:text-blue-500 transition duration-300 ease-in-out"
        style="font-size: 14px;"
      >
        Report Case
      </router-link>
      <router-link
        to="/watersourcestatus"
        class="text-sm text-black hover:text-blue-500 transition duration-300 ease-in-out"
        style="font-size: 14px;"
      >
        Water Source Status
      </router-link>
      <router-link
        to="/feeds"
        class="text-sm text-black hover:text-blue-500 transition duration-300 ease-in-out"
        style="font-size: 14px;"
      >
        Feeds
      </router-link>
      <router-link
        to="/contact"
        class="text-sm text-black hover:text-blue-500 transition duration-300 ease-in-out"
        style="font-size: 14px;"
      >
        Contact
      </router-link>
    </div>

    <!-- Sign-up Modal -->
    <div v-if="showSignUpModal" class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
      <!-- Sign-up form content -->
      <div class="bg-white p-4 rounded-lg">
        <h2 class="text-xl font-semibold mb-4">{{ isSubscribed ? 'Thank you for subscribing!' : 'Subscribe to our updates' }}</h2>
        <!-- Sign-up form inputs and submit button -->
        <input v-model="email" v-if="!isSubscribed" type="email" class="w-full p-2 mb-2" placeholder="Enter your email" />

        <button @click="subscribe" :disabled="isSubscribed">{{ isSubscribed ? 'Close' : 'Subscribe' }}</button>

        <button v-if="!isSubscribed" @click="closeSignUpForm" class="text-blue-500 hover:underline">Cancel</button>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showSignUpModal: false,
      showMobileNav: false,
      email: '',
      isSubscribed: false,
    };
  },
  methods: {
    async subscribe() {
      try {
        const requestData = { email: this.email };
        console.log('Request Data:', requestData);

        const response = await axios.post('https://jangavoicesystem.com.parlafrica.org/adminpanel/api/subscribers/', requestData);

        if (response.status === 201) {
          this.isSubscribed = true;
        } else {
          console.log('Subscription failed:', response);
        }
      } catch (error) {
        console.error('Error subscribing:', error);
        // Handle error scenarios here
      } finally {
        // Close the form even if there's an error or the subscription is successful
        this.showSignUpModal = false;
      }
    },

    closeSignUpForm() {
      this.showSignUpModal = false;
    },
    showSignUpForm() {
      this.showSignUpModal = true;
    },
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    // Add other methods as needed
  },
};
</script>

<style scoped>
/* Your existing styles */

/* Additional styles for responsiveness */
.h-16 {
  height: 4rem; /* Set your desired height */
}

/* Add margin to the Sign Up button */
.md\:hidden {
  display: none; /* Hide the mobile navigation button initially */
}

/* Stylish separator */
.menu-link {
  position: relative;
  padding: 0 10px;
  font-size: 16px; /* Adjust as needed */
  line-height: 2rem;
  color: black;
  text-decoration: none;
}

.menu-link::after {
  content: '|';
  position: absolute;
  right: 0;
  color: #3182ce; /* Adjust color as needed */
}

.menu-link:last-child::after {
  content: none; /* Remove separator after the last menu item */
}

@media (max-width: 768px) {
  /* Center the mobile navigation links */
  .md\:flex {
    justify-content: center;
  }

  .md\:block {
    display: flex;
    justify-content: flex-end;
  }

  .md\:hidden {
    display: block; /* Show the mobile navigation button */
    margin-right: 2rem; /* Adjust the margin as needed */
  }
}
</style>

