<template>
  <div>
    <div
      class="hero-section bg-cover bg-center h-screen flex items-center justify-center text-black"
    >
      <div class="text-center">
        <a
          href="#"
          class="bg-blue-500 text-white py-2 px-6 rounded-full text-lg hover:bg-blue-700"
        >
          JangaVoice
        </a>
        <p class="text-lg mb-8"></p>
        <h1 class="text-4xl font-bold mb-4">
          Your Voice in Disaster Preparedness!
        </h1>
      </div>
    </div>



    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
      <div
        v-for="imageWithDescription in imagesWithDescriptions"
        :key="imageWithDescription.id"
      >
        <div
          class="rounded border overflow-hidden shadow-lg hover:shadow-xl transition"
        >
          <h2 class="text-lg font-bold p-4">
            {{ imageWithDescription.title }}
          </h2>
          <img
            :src="imageWithDescription.image"
            alt="Image"
            class="w-full h-48 object-cover"
          />
          <p class="text-gray-600 p-4">
            {{ imageWithDescription.description }}
          </p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
      <div v-for="imageWithTitle in imagesWithTitles" :key="imageWithTitle.id">
        <div
          class="rounded border overflow-hidden shadow-lg hover:shadow-xl transition"
        >
          <h2 class="text-lg font-bold p-4">{{ imageWithTitle.title }}</h2>
          <img
            :src="imageWithTitle.image"
            alt="Image"
            class="w-full h-48 object-cover"
          />
        </div>
      </div>
    </div>

    <div class="swiper-container" v-if="imagesWithLinks.length > 0">
      <div class="swiper-wrapper">
        <div
          v-for="imageWithLink in imagesWithLinks"
          :key="imageWithLink.id"
          class="swiper-slide"
        >
          <div
            class="rounded border overflow-hidden shadow-lg hover:shadow-xl transition"
          >
            <img
              :src="imageWithLink.image"
              alt="Image"
              class="w-full h-auto object-cover"
            />
            <div class="p-4">
              <h2 class="text-lg font-bold">{{ imageWithLink.title }}</h2>
              <a :href="imageWithLink.link" class="block p-2 text-blue-500"
                >Visit Link</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <!--<p>No Images with Links Available</p>-->
    </div>
  </div>
  <div
    class="feature-section  bg-slate-100 p-2"
  >
  <h1 class="font-bold">Featured</h1>
  <div class="grid grid-cols-3 sm:grid-cols-3 p-5 gap-5">
  <router-link
              to="/about"
            >
            <div class="feature-card">
              <h1><u>About</u></h1>
              <p>What is Janga voice ?</p>
            </div>
            </router-link>
            <router-link
            to="/feeds"
          >
          <div class="feature-card">
            <h1><u>Feeds</u></h1>
            <p>Janga Voice News</p>
          </div>
          </router-link>
       <!-- <a href="https://www.dropbox.com/scl/fi/z7v04nuj51yd68f5gh7yb/app-release.apk?rlkey=tqa61l92lrahow03wen0km8a9&st=h5r33i2x&dl=1">-->
        <a href="app-release.apk" download="jangavoice.apk">
        <div class="feature-card ">
          <h1 style="color:blue"><u>Downloads</u></h1>
          <p>Get Janga Voice Mobile app</p>
        </div>
      </a>
        <router-link
        to="/reportedcase"
      >
      <div class="feature-card">
        <h1><u>Reported Cases</u></h1>
        <p>Recent Updates from community</p>
      </div>
      </router-link>
      <router-link
        to="/watersourcestatus"
      >
      <div class="feature-card">
        <h1><u>Water Source Status</u></h1>
        <p>Get quick Information</p>
      </div>
      </router-link>
      <router-link
        to="/contact"
      >
      <div class="feature-card">
        <h1><u>Contact Us</u></h1>
        <p>More about janga Voice</p>
      </div>
      </router-link>
      </div>
    </div>
    <div class="patners p-2">
      <h1 class="font-bold">Our Patners</h1>
      <div class="flex flex-wrap justify-center gap-8 p-6">
        <div class="w-24 h-24 rounded-full overflow-hidden shadow-lg">
          <img src="parl.png" alt="Partner 1" class="w-full h-full object-cover">
        </div>
        <div class="w-24 h-24 rounded-full overflow-hidden shadow-lg">
          <img src="maganatti.jpeg" alt="Partner 2" class="w-full h-full object-cover">
        </div>
        <!-- Repeat for additional partners -->
      </div>

    </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      textSections: [],
      imagesWithTitles: [],
      imagesWithDescriptions: [],
      imagesWithLinks: [],
      swiper: null,
    };
  },

  mounted() {
    this.fetchTextSections();
    this.fetchImagesWithTitles();
    this.fetchImagesWithDescriptions();
    this.fetchImagesWithLinks();
  },

  methods: {
    fetchTextSections() {
      axios
        .get("https://jangavoice.com/siteupdate/api/text-section/")
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.textSections = response.data;
          } else {
            console.error("Unexpected response format:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching text section details:", error);
        });
    },

    fetchImagesWithTitles() {
      axios
        .get("https://jangavoice.com/siteupdate/api/image-with-title/")
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.imagesWithTitles = response.data;
          } else {
            console.error("Unexpected response format:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching image with title details:", error);
        });
    },

    fetchImagesWithDescriptions() {
      axios
        .get(
          "https://jangavoice.com/siteupdate/api/image-with-title-and-description/"
        )
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.imagesWithDescriptions = response.data;
          } else {
            console.error("Unexpected response format:", response.data);
          }
        })
        .catch((error) => {
          console.error(
            "Error fetching image with description details:",
            error
          );
        });
    },

    fetchImagesWithLinks() {
      axios
        .get("https://jangavoice.com/siteupdate/api/image-with-title-and-link/")
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.imagesWithLinks = response.data;
          } else {
            console.error("Unexpected response format:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching image with link details:", error);
        });
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-image: url("/src/assets/janga.jpg");
  height: 300px;
}
</style>
