<template>
  <footer class="bg-gray-800 text-white py-8">
    <div class="grid grid-cols-2">
    
    <div class="container mx-auto flex flex-wrap justify-between">
      <div class="w-full md:w-1/2 lg:w-1/3">
        <h3 class="text-lg font-bold mb-4">Quick Links</h3>
      <ul v-if="footerData && footerData.water_source_status_link">
  <li><a :href="footerData.home_link" class="text-white">Home</a></li>
  <li><a :href="footerData.about_link" class="text-white">About</a></li>
  <li><a :href="footerData.report_case_link" class="text-white">Report Case</a></li>
  <li><a :href="footerData.water_source_status_link" class="text-white">Water Source Status</a></li>
  <li><a :href="footerData.feeds_link" class="text-white">Feeds</a></li>
  <li><a :href="footerData.contact_link" class="text-white">Contact</a></li>
  <!-- Add other links as needed -->
</ul>

        <p v-else>Loading...</p>
      </div>
      <div class="w-full md:w-1/2 lg:w-1/3">
        <!-- Add more sections as needed -->
      </div>
    </div>
    <div class="w-full md:w-1/2 lg:w-1/3">
      <h3 class="text-lg font-bold mb-4">About Us</h3>
      <p>{{ footerData ? footerData.description : 'Loading...' }}</p>
    </div>
    </div>
    <br/>
    <p>&copy; 2024 Janga Voice. All rights reserved.</p>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      footerData: null,
    };
  },
  mounted() {
    this.fetchFooterData();
  },
  methods: {
  async fetchFooterData() {
    try {
      const response = await axios.get('https://jangavoice.com/siteupdate/api/footer/');
      if (Array.isArray(response.data) && response.data.length > 0) {
        this.footerData = response.data[0];
      } else {
        console.error('Unexpected response format for footer data:', response.data);
      }
    } catch (error) {
      console.error('Error fetching footer data:', error);
    }
  },
},

};
</script>
